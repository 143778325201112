import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of } from 'rxjs';

/**
 * Personalized preloading strategy
 *
 * It will preload the modules that have the preload property set to true
 * in the data property of the route.
 */
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      return load();
    } else {
      return of(null);
    }
  }
}
