import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ApiService } from '../../../services/api.service';
import { LoadingStore } from '../../../stores/loading.store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Auth service, used to refresh the token
   */
  authService: AuthService | undefined;

  constructor(
    private router: Router,
    private inj: Injector,
    private loading: LoadingStore,
    private cookieService: CookieService,
    private apiService: ApiService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.apiService.getApiUrl())) {
      return this.reallyDoInterception(request, next);
    } else {
      return next.handle(request);
    }
  }

  reallyDoInterception(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // We should get the AuthService like this to avoid circular dependencies
    this.authService = this.inj.get(AuthService);

    // CSRF token must be sent as a header since flask requires it
    const csrfToken = this.cookieService.get('csrf_access_token');
    request = request.clone({
      headers: csrfToken ? request.headers.set('X-XSRF-TOKEN', csrfToken) : undefined,
      withCredentials: true
    });

    // Handle the request
    return next.handle(request).pipe(
      catchError((error: any) => {
        this.loading.stop();
        // If the token is expired...
        switch (error.status) {
          case 401:
            // If session is expired, redirect to login
            this.authService?.logout(false);
            this.router.navigate(['login']);
            break;
          /*TODO: Add the 403 case*/
          // case 403:
          //   // If the user is not authorized, reload the profile data in case the permissions have changed
          //   this.authService?.fillUserData(false);
          //   break;
        }

        return observableThrowError(error);
      })
    );
  }
}
