import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader, formatLanguage } from 'src/utils/translate';

import { LoginClientComponent } from './login-client/login-client.component';
import { LoginFormComponent } from './login-client/login-form/login-form.component';
import { LoginComponent } from './login.component';
import { environment } from '../../environments/environment';

/**
 * TODO: this module is included here because of some Angular changes that broke the original ng-login component.
 * Revisit and fix in the future.
 **/

/**
 * Method to get the domains from the environment
 */
export function getDomains(): (string | RegExp)[] {
  const currentUrl = new URL(window.location.href);
  const baseDomain = new URL(environment.apiPath, `${currentUrl.protocol}//${currentUrl.hostname}`);

  return [new RegExp('.*.?' + baseDomain)];
}

/**
 * NG-Login module
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatCheckboxModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: formatLanguage(navigator.language)
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MatDialogModule,
    LoginComponent,
    LoginClientComponent,
    LoginFormComponent
  ],
  exports: [LoginComponent, MatFormFieldModule, LoginFormComponent]
})

/**
 * Login module class
 */
export class LoginModule {}
