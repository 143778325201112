import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

/**
 * Guard to be used in routes definition, field 'canActivate'
 */
@Injectable()
export class AuthGuardAdmin implements CanActivate {
  /**
   * Constructor
   * @param auth
   * @param router
   */
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  /**
   * Whether the current user can access to a view or not
   * @returns {boolean}
   */
  public canActivate(route: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): boolean {
    const redirectURL = 'admin/login';

    if (!this.auth.loggedIn() || !this.auth.hasSameRole(route.data.role)) {
      this.auth.redirectUrl = url;
      this.router.navigateByUrl(redirectURL);

      return false;
    }

    return true;
  }
}
