import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FooterComponent } from './shared-components/footer/footer.component';
import { SessionManagerService } from '../utils/session-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, FooterComponent]
})
export class AppComponent {
  title = 'rent-a-bus-front';

  /**
   * AppComponent constructor
   * @param sessionManager Used to manage different user sessions
   * at the same time. It is not necessary to use it, just
   * declare it in the constructor
   */
  constructor(private sessionManager: SessionManagerService) {}
}
