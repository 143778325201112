import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/definitions/user-role.enum';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule]
})
export class FooterComponent implements OnInit, OnDestroy {
  currentYear = new Date().getFullYear();
  isAdminRoute? = false;
  routerSubscription?: Subscription;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        event.url.includes('admin') ? (this.isAdminRoute = true) : (this.isAdminRoute = false);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  redirectToContact(): void {
    if (this.auth.hasSameRole(UserRole.OPERATOR)) {
      this.router.navigate(['contact'], { queryParams: { role: UserRole.OPERATOR } });
    } else if (this.auth.hasSameRole(UserRole.CLIENT)) {
      this.router.navigate(['contact'], { queryParams: { role: UserRole.CLIENT } });
    } else {
      this.router.navigate(['contact']);
    }
  }
}
